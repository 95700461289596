
const routes = [
    
    {
      path: '/',
      name: '首页',
      meta: {
        title: '首页'
      },
      component: () => import(`@/views/bighome/index.vue`),
      children: [
        {
          path: '/',
          name: '',
          component: () => import("@/views/aboutUs/index.vue"),
        },
        {
            path: '/Journalism',
            name: '',
            component: () => import("@/views/Journalism/index.vue"),
        },
        {
          path: '/news',
          name: '',
          component: () => import("@/views/news/index.vue"),
      },
      ]
    }
  ]
  //导出路由
  export default routes