import { createRouter, createWebHashHistory } from 'vue-router'
import routes from "./router";
import NProgress from "nprogress";
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})
router.beforeEach((to, from) => {
  
  NProgress.start()
  return true
})
router.afterEach((to, from) => {

  NProgress.done()
})
export default router
