<template>
  <div id="App">
    <div id="Product">
      <div class="titlea">新闻动态</div>

      <div class="Product_listB">
        <div class="Product_listD">
          <img src="@/assets/news.jpg" alt="" />
        </div>
        <div class="Product_listC">
          <div
            @click="onnew(index)"
            v-for="(index, i) in Product_listB"
            :key="i"
            class="news"
          >
            <div class="datanews">
              <ul>
                <li style="font-size: 1.8rem">{{ index.tian }}</li>
                <li style="font-size: 0.2rem">{{ index.nianyue }}</li>
              </ul>
            </div>
            <div class="newstext">
              <div class="newstitle">{{ index.title }}</div>
              <div class="newstextB">{{ index.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  //数据
  data() {
    return {
      Product_list: [
        {
          name: "二手发布",
        },
        {
          name: "求购发布",
        },
        {
          name: "宠物发布",
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapState(["Product_listB"]),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(["add"]),
    onnew(res) {
      console.log("aaaaaa", res.newtext);
      let id = encodeURIComponent(JSON.stringify(res.newtext));
      this.$router.push({
        path: "/news",
        query: {
          id,
        },
      });
      this.add(2);
    },
  },
};
</script>

<style scoped lang='scss'>
#App {
  #Product {
    margin: auto;
    width: 62%;

    margin-top: 20px;
    .titlea {
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 24px;
    }
    .Product_list {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      div {
        margin: 0 5px;
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
        border: 1px solid #0ae;
        color: #0ae;
      }
    }
    .Product_listB {
      display: flex;
      margin-bottom: 80px;
      height: 300px;

      .Product_listD {
        height: 300px;
        width: 380px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .Product_listC {
        width: calc(100% - 380px);

        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .news {
          padding: 10px;
          width: 45%;
          height: 80px;
          border-bottom: 1px dashed #ddd;
          display: flex;
          .datanews {
            width: 100px;
            height: 100%;
            background: #f2f2f2;
            display: flex;
            align-items: center;
            justify-content: center;
            ul {
              li {
                color: #666666;
                text-align: center;
              }
            }
          }
          .newstext {
            width: calc(100% - 100px);
            padding: 0 10px;
            .newstitle {
              width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 18px;
              color: #333333;
            }
            .newstextB {
              width: 100%;
              word-break: break-all;
              color: #999999;
              text-overflow: ellipsis;
              margin-top: 10px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-size: 0.75rem;
              overflow: hidden;
            }
          }
        }
        .news:hover {
          cursor: pointer;
          .newstitle {
            color: #0ae;
          }
        }
      }
    }
  }
}
</style>
