<template>
  <div id="App">
    <div id="text">
      <div id="text-font">
        <div id="text-font-title">关于我们</div>
        <div id="text-font-text">{{ introduceTextB }}</div>
        <el-button @click="onbutton" class="el-button" type="primary"
          >了解更多>></el-button
        >
      </div>
      <div id="text-img">
        <div id="text-font-title">加入群聊</div>
        <Carousel
          :texta="true"
          style="margin-top: 20px"
          :height="height"
          :imgList="imgList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Carousel from "./Carousel.vue";
export default {
  components: { Carousel },
  //数据
  data() {
    return {
      introduceTextB: "",
      introduceText:
        '上海驰迅科技有限公司是一家专注于技术服务的创新型公司，公司本着“个性与品质”的发展理念，以创新而可靠的技术为主导，以灵活而细致的销售为辅助，以专业的技术团队为支撑，以诚信经营贴心的服务为助推，在市场实践中发展壮大。公司旗下的平台"易手客",是公司为广大学生搭建的交流二手物品的可靠诚信的舞台。用"我"之余易"我"不足,把累赘变成可用之物,"易手客",轻松成就你我。',
      num: 1,
      done: {},
      action: true,
      height: "320px",
      imgList: [require("@/assets/weixin.png"), require("@/assets/QQ.png")],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("scroll", this.menu);
  },
  methods: {
    ...mapMutations(["add"]),
    show() {
      this.done = setInterval(() => {
        var shower = this.introduceText.substr(0, this.num);
        this.introduceTextB = shower;
        this.num++;
        if (this.num + 1 >= this.introduceText.length) {
          clearInterval(this.done);
        }
      }, 100);
    },

    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll >= 100 && this.action) {
        this.action = false;
        this.show();
      }
    },
    onbutton() {
      this.add(1);
      this.$router.push({
        //核心语句
        path: "/Journalism", //跳转的路径
      });
    },
  },
};
</script>

<style scoped lang='scss'>
#App {
  width: 100%;
  height: 450px;

  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  #text {
    width: 62%;
    height: 80%;
    display: flex;
    #text-font {
      flex: 1;
      height: 100%;
      margin-right: 10px;
      #text-font-title {
        font-size: 22px;
      }
      #text-font-text {
        margin-top: 20px;
        line-height: 24px;
        height: 200px;
      }
    }
    #text-img {
      flex: 0.5;
      height: 90%;
      #text-font-title {
        font-size: 22px;
      }
    }
  }
}
</style>
