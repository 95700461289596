import { createStore } from 'vuex'

export default createStore({
  state: {
    text: localStorage.getItem("stepte")===null?0:parseInt(localStorage.getItem("stepte")) ,
    Product_listB: [
      {
        tian: "14",
        nianyue: "2021/10",
        title: "现在的孩子到底怎么了？",
        text: "现在的孩子到底怎么了？老一辈的总是抱怨现在的孩子太难带，老师们也觉得他们教的孩子一届不如一届。",
        newtext:[
          {
            type:'text',
           value:'现在的孩子到底怎么了？老一辈的总是抱怨现在的孩子太难带，老师们也觉得他们教的孩子一届不如一届。可是，你有没有想过，从80年代开始，孩子们的成长环境发生了巨大的改变，我们现在面对的教育对象是00后和10后，他们大都是独生子女，也是伴随着互联网的发展成长起来的一辈人。'
          },
          {
            type:'img',
            img: require("@/assets/a/a1.png"),
          },
          {
            type:'text',
            value:'其实孩子们也有很多苦恼，只是我们没有深入了解。时代在变，老师和家长们的教育观念也应该转变，当一个民族吃饭不愁，尤其是到我们这样的民族吃饭不愁的时候，我们会愁什么呢？我们的需要开始变得不一样了，人对人的需要不一样了，家长对孩子的需要不一样了，社会对人的需要不一样，人对社会的需要不一样了。'
          },
          {
            type:'img',
            img: require("@/assets/a/a2.png"),
          },
          {
            type:'text',
            value:'现在的孩子带着天生的孤独感来到这个世界。那么孤独会有哪些麻烦？一，人一孤独，无端伤感，莫名其妙流眼泪；二，二，人一孤独，思考力就变得非常强，所以麻烦就来了，既不思考吃，又不思考穿，他就过早地思考了一个终极问题，最高哲学问题--“我是谁？”我们现在的孩子，连四岁的孩子就会在那里发呆了，你不知道他小脑袋里想什么。一旦人有这种问题，痛苦就伴随着他了。所以我们教育面对的问题是我们得知道自己的子女是什么人，才可以有方向。'
           },
           {
            type:'img',
             img: require("@/assets/a/a3.png"),
           },
           {
            type:'text',
             value:'回想我们小时候，大部分人都生活在多子女家庭，父母很难投入过多的关注在某一个孩子身上。但是现在的孩子是什么样？上海这样的城市里，一个孩子来到这个世界上，就有六个人围着他，这六个人会把最好的付出给他。那么六个人爱一个孩子，你能体会到这个孩子的感受吗？你知道这个孩子的内心世界吗？他内心里面的苦闷你知道吗？你问他想不想考大学？他回答，想，还要考好的。那为什么不投入呢？因为他们焦虑不堪。只要说一句话他们就会淌眼泪：“你可能考不到你觉得理想的大学，然后你会觉得实在对不起你的家长，他们对你太好了，是不是？”孩子们一听眼泪就淌下来。孩子们太可怜了。'
           },
           {
            type:'img',
             img: require("@/assets/a/a4.png"),
           },
           {
            type:'text',
            value:'幸福不是别人能给的，而是自己的主观体验。父母们要告诉孩子不要背这个包袱，轻装往前走，这个才是给孩子正能量。讲一句贴心的话，我们把孩子带到这个世界上来，你要记住，不是他要来的，如果他要选择未必会选你，你是单向选择。我跟我孩子经常说的话是，“孩子，咱们父女一场，彼此不要嫌弃，你不嫌我，我不嫌你。”孩子没有这种重担，他才能轻装往前走，他才不会有考试焦虑。现在咱们国家有多少学生因为在临考前焦虑不堪，学习成绩才没有达到应该有的目标呀！我们能看到现在的孩子有空前的学业压力，这只是显性的，而隐性的东西是孩子们整天跟焦虑高危人群在一起，这些人是孩子的父母、老师。你想想看，如果孩子在学校、在家里都要面对一群焦虑的人，人人对孩子拉橡皮筋，老师在学校里不停强调考试、学习；孩子回到家，家长也在强调考试、学习，那孩子可能就完了。'
           },
           {
            type:'img',
             img: require("@/assets/a/a5.png"),
           },
                   
        ]
      },
      {
        tian: "9",
        nianyue: "2021/10",
        title: "当年迟到的学生长大了",
        text: "10月7日，黑龙江绥化一老师因睡过头错过了上网课,手机被学生轰炸的视频在网上引发网友关注，网友纷纷调侃，“当年迟到的学生长大了。",
        newtext:[
          {
            type:'text',
           value:'10月7日，黑龙江绥化一老师因睡过头错过了上网课,手机被学生轰炸的视频在网上引发网友关注，网友纷纷调侃，“当年迟到的学生长大了。”'
          },
          {
            type:'img',
            img: require("@/assets/b/b1.png"),
          },
          {
            type:'text',
            value:'涉事女教师10月7号在社交平台发布一则视频，视频中显示，该女教师的微信被学生信息“轰炸”，学生们纷纷发消息喊老师起床，“老师怎么不上课？”更有学生调侃，“老师你醒醒，一会儿你的手机会被轰炸。”也有学生发消息关心，“刚才老担心你了。”'
          },
          {
            type:'img',
            img: require("@/assets/b/b2.png"),
          },
          {
            type:'text',
            value:'10月8日，该女教师发布视频回应睡过头事件称:针对此次迟到事件，首先对昨天等待自己上课的所有同学们道歉，作为一名教师不应该犯这样的错误，缺的课一定给大家补上，以后一定引以为戒，不辜负学生的喜欢。也希望学生把所有的调侃放到课后，把认真放在课堂上。该女教师在最后提醒同学们以后有事要多定几个闹钟。'
           },
           {
            type:'img',
             img: require("@/assets/b/b3.png"),
           },
           
           {
            type:'img',
             img: require("@/assets/b/b4.png"),
           },
          
           {
            type:'img',
             img: require("@/assets/b/b5.png"),
           },
           {
            type:'text',
             value:'据悉，由于疫情等原因，现在学校采取网课形式教学。'
           },
           {
            type:'img',
             img: require("@/assets/b/b6.png"),
           }, {
            type:'img',
             img: require("@/assets/b/b7.png"),
           },
           {
            type:'img',
             img: require("@/assets/b/b8.png"),
           },
           {
            type:'text',
             value:'10月8日，此事引起网友的关注，网友调侃，“现在全国人民都知道你迟到了。”有网友留言，“当年迟到的孩子长大了。”也有网友留言，“老师褪去职业也是个小女孩”。'
           },
        ]
      },
      {
        tian: "16",
        nianyue: "2021/09",
        title: "教育部发布“最新”通知，严查隐形变异培训，这几类补课不允许",
        text: "为了有效缓解中小学生课业负担以及校外培训负担，“双减”政策应运而生，与“双减”政策配套实施的还有从秋季学期开始所有义务教育学校均将推行课后延时服务。",
        newtext:[
          {
            type:'text',
           value:'为了有效缓解中小学生课业负担以及校外培训负担，“双减”政策应运而生，与“双减”政策配套实施的还有从秋季学期开始所有义务教育学校均将推行课后延时服务。'
          },
          {
            type:'text',
           value:'所以从目前的情况来看，校外培训机构提供的服务少了，学校提供的服务多了，让教育回归校园的大趋势已经成型。'
          },
          {
            type:'img',
            img: require("@/assets/c/c1.png"),
          },
          {
            type:'text',
            value:'不过对于部分家长们而言，他们确实存在一定的补课需求，毕竟虽然学校提供了课后延时服务，但是在此期间不允许讲授新课，也不可能让老师对自己孩子单独补课，所以如果想要提升孩子成绩，巩固薄弱基础，那么还得依赖于校外培训才行。'
          },
          {
            type:'text',
            value:'而按照“双减”政策的相关要求，校外学科类培训机构能够提供培训的时间主要集中在周中的晚上，所以家长们只能在孩子下午放学后再安排孩子前往培训机构补课， 如此一来，孩子们的学业压力就会增大，而且这样高强度的学习，也难以保证较好的学习效果。'
          },
          {
            type:'text',
            value:'那么这种情况下，家长们就会想办法能否找到一些培训机构或个人在周末、节假日或者寒暑假期提供相应的培训。'
          },
          {
            type:'text',
            value:'当然，从政策上来说，这几乎不可能。但是确实有部分培训机构和个人通过一些隐性变异培训的方式，持续在上述时间段提供相应的学科培训服务。'
          },
          {
            type:'text',
            value:'不过这种情况可能维持不了多久了。'
          },
          {
            type:'text',
            value:'教育部发布“最新”通知，严查隐形变异培训，这几类补课不允许'
          },
          {
            type:'text',
            value:'9月23日，教育部召开了新闻通气会，会上教育部校外教育培训监管司相关负责人就指出要严查隐形变异培训，其中重点指出了4种违规补课类型。'
          },
          {
            type:'img',
            img: require("@/assets/c/c2.png"),
          },
          {
            type:'text',
            value:'1、“一对一”家教'
           },
           {
            type:'text',
            value:'部分家长会请老师给孩子进行“一对一”授课，比如和老师约定时间，在周末、节假日等时间上门补课。'
           },
           {
            type:'img',
             img: require("@/assets/c/c3.png"),
           },
           {
            type:'text',
            value:'2、“高端家政”'
           },
           {
            type:'text',
            value:'部分家政公司不仅仅提供普通家政，而且还适应潮流推出了“高端家政”，所谓“高端家政”并不是提供更优质的家政服务，而实际上却是提供的家教服务，只是以家政为幌子。'
           },
           {
            type:'text',
            value:'3、“众筹私教”'
           },
           {
            type:'text',
            value:'实行“双减”政策后，校外培训机构不便于提供学科类培训，于是部分有培训需求的家长们便一起众筹，共同聘请一位或多位教师给他们的孩子上课。'
           },
           {
            type:'text',
            value:'4、“住家教师”'
           },
           {
            type:'text',
            value:'部分家境优越的家庭会主动让家教老师住在自己家中，平日里主要负责孩子的教育问题，包括作业辅导、学科培训、学习生活习惯培养等。'
           },
           {
            type:'img',
             img: require("@/assets/c/c4.png"),
           },
           {
            type:'text',
            value:'对于上述4种类型的违规补课行为，教育部明确强调不允许进行相应的补课，所以不论是培训需求方，还是培训服务提供方都得多加留意。'
           },
           {
            type:'text',
            value:'违规补课监管难'
           },
           {
            type:'text',
            value:'不过，虽然教育部表明要严查违规补课行为以及隐形变异培训，但这种违规行为很难监管。'
           },
           {
            type:'text',
            value:'比如家长聘请“住家教师”，如何分辨“住家教师”，谁又有权利前往别人家中随意进行核查？'
           },
           {
            type:'text',
            value:'所以很多违规培训具有隐蔽性和私密性的特点，对于监管人员来说，想要有效监管难度很大。'
           },
           {
            type:'text',
            value:'培训需求如何解决？'
           },
           {
            type:'text',
            value:'此外，部分家长确实是自身具备一定的培训需求，但是又不想在周中时间给孩子报名参加辅导班，那么他们的培训需求如何解决？'
           },
           {
            type:'text',
            value:'也正是因为有这样的需求存在，所以才为违规变异培训提供了生存空间和营利空间，所以问题关键点在于让家长们正确树立育人理念，与此同时还得优化升学选拔标准。'
           },
           {
            type:'img',
             img: require("@/assets/c/c5.png"),
           },
           {
            type:'text',
             value:'如果中考、高考依然采用分数作为最终评选依据，那么家长们就注定会为了让孩子取得更高的分数而费尽脑汁。家境差一些的或许会安于现状，而家境优越一些的就会想办法给孩子制造培训机会。'
           },
          
           {
            type:'text',
             value:'所以让家长们难以释怀的不是补课问题，而是孩子的升学焦虑。如何化解这种焦虑更应当值得深入思考。'
           },
        ]
      },
      {
        tian: "10",
        nianyue: "2021/07",
        title: "刚过去的教师节，你送礼了吗？",
        text: "“家委会”为何成了“烫手山芋”孩子们开学半个月了，教师节也过去了五天。新学期伊始新生家长们就不淡定了。",
        newtext:[
          {
            type:'text',
           value:'“家委会”为何成了“烫手山芋”孩子们开学半个月了，教师节也过去了五天。新学期伊始新生家长们就不淡定了。“教师节”还没到，家委会有些家长四处张罗、私下联络、催收班费，要给老师一个特别的礼物。'
          },
          {
            type:'text',
           value:'“教师节”是国家给教师的法定假日，这一天，应是老师们倍感荣耀的日子，是一个象征性的节日，可是现在越来越变味了。甚至有老师特别在网上吐露心声：我是教师，我希望取消“教师节”！'
          },
          {
            type:'text',
           value:'老师们为什么会有这样的想法呢？部分家委会家长们的送礼与攀比，过于流于表面的形式，过度的仪式感，又有财政压力和人情世故两相掺杂，弄得老师们很累，家长们也累。'
          },
          {
            type:'text',
           value:'作为家长，大家都在谈这件事，都在送礼，你的孩子也在上学，你送还是不送呢？作为老师，一群家长以各种方式送一些并不需要的东西，这所谓的礼，是收还是不收呢？'
          },
          {
            type:'img',
            img: require("@/assets/d/d1.png"),
          },
          {
            type:'text',
           value:'知乎上有人问送老师礼物的问题，一个高赞回答是：孩子与老师相处很久，关系融洽，教导了很多，家长发自内心觉得老师很好，节日时送上小礼和贺卡表达感谢和尊重，这就叫礼物。孩子还没有和老师见过，或者初次送礼就暗示多加关照，这叫交换。'
          },
          {
            type:'img',
            img: require("@/assets/d/d2.png"),
          },
          {
            type:'text',
           value:'大多数“教师节”的礼物，有着“交换”的意味。天下没有免费的午餐，同样，也没有白送的礼。家长费尽心思如此操作，无非是希望老师多给孩子一些照顾。可是一旦老师做得不够好，没达到预期效果，这些家长又会充满愤怒，各种诽谤。在网上各种讨论中，也常看到一些送了礼又骂老师的家长发的言论。'
          },
          {
            type:'text',
           value:'最好的礼物：共同教育，相互支持，与其费尽心思去送礼，不如好好地教育孩子。早点跟孩子讲一讲“教师节”的来历，聊一聊老师的工作和付出，说一说自己眼中的老师，听一听孩子眼中的老师。以润物细无声的方式，让孩子明白，老师，这个没有血缘的亲人，是值得学生一生铭记和感恩的人。让孩子真正懂得什么是“教师节”，并打心底里尊敬老师！如此之后，再在“教师节”即将来临的前夕，与孩子一起为老师做一张贺卡，或者做一份特别的手工，既培养了孩子的感恩心，又让孩子对老师有了真正自主的情感表达方式，这样的教育，才是对孩子最有益的。'
          },
          {
            type:'img',
            img: require("@/assets/d/d3.png"),
          },
          
        ]
      },

    ],
  },
  mutations: {
    //不能写异步操作，只能同步操作
    add(state,stepte) {
      console.log(stepte,typeof(stepte));
      localStorage.setItem("stepte", stepte);  
      let value = localStorage.getItem("stepte")
      state.text= parseInt(value)
      console.log(state.text,typeof(state.text));
    },
    router_(state,step){

      console.log(step);
    }
  },
  actions: {
    //异步调用mutations里的方法，不能直接修改state里的值
    addyibu(context, step) {
      setTimeout(() => {
        context.commit('add', step)
      }, 2000);
    }
  },
  getters: {
    //不会改变state里的值，类似VUE的计算属性
    addjisuan(state) {
      return state.text + state.list
    },
  },
  modules: {
  }
})
