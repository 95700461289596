<template>
  <div>
    <el-carousel :interval="4000" :height="height">
      <el-carousel-item v-for="item in imgList" :key="item">
        <el-image class="el-image" :src="item" alt=""> </el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {};
  },
  props: ["height", "imgList", "texta"],
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
.text {
  position: absolute;
  z-index: 1000;
  background: #000;
  width: 100%;
  top: 0;
  height: 20%;
  opacity: 0.5;
}
.el-image {
  width: 100%;
  height: 100%;
}
</style>
