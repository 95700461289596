<template>
  <div id="App">
    <div id="Product">
      <div class="titlea">分类中心</div>
      <div class="Product_list">
        <div v-for="(index, i) in Product_list" :key="i">
          {{ index.name }}
        </div>
      </div>
      <div class="Product_listB">
        <div class="Product_listC" v-for="(index, i) in Product_listB" :key="i">
          <div class="Product_img">
            <img :src="index.img" alt="" />
          </div>
          <div class="Product_name">{{ index.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {
      Product_list: [
        {
          name: "二手发布",
        },
        {
          name: "求购发布",
        },
        {
          name: "宠物发布",
        },
      ],
      Product_listB: [
        {
          name: "电子电器",
          img: require("@/assets/jiadian.jpg"),
        },
        {
          name: "日常用品",
          img: require("@/assets/dianzi.jpg"),
        },
        {
          name: "书籍资料",
          img: require("@/assets/shuji.jpg"),
        },
        {
          name: "宠物中心",
          img: require("@/assets/chongwu.jpg"),
        },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
#App {
  #Product {
    margin: auto;
    width: 62%;
    margin-top: 20px;
    .titlea {
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 24px;
    }
    .Product_list {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      div {
        margin: 0 5px;
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
        border: 1px solid #0ae;
        color: #0ae;
      }
    }
    .Product_listB {
      display: flex;
      justify-content: center;
      margin-bottom: 80px;
      height: 330px;
      .Product_listC {
        flex: 1;
        margin: 0 10px;
        .Product_img {
          width: 100%;
          height: 90%;
          border: 1px solid #999;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 92%;
            height: 92%;
            transition: 0.3s;
          }
        }
        .Product_name {
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 18px;
        }
      }
      .Product_listC:hover {
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .Product_name {
          color: #0ae;
        }
      }
    }
  }
}
</style>
