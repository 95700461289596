<template>
  <div id="App">
    <div id="list">
      <div
        v-for="(index, i) in imgList"
        :key="i"
        class="list_"
        :style="{ background: i === 1 ? 'rgb(50, 139, 168)' : '#09c' }"
      >
        <img :src="index.img" alt="" />
        <div>{{ index.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {
      imgList: [
        {
          img: require("@/assets/security.png"),
          name: "安全",
        },
        {
          img: require("@/assets/simple.png"),
          name: "简单",
        },
        {
          img: require("@/assets/Efficient.png"),
          name: "高效",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
#App {
  height: 200px;
  background: #09c;
  #list {
    width: 62%;
    height: 100%;
    margin: auto;
    display: flex;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
    .list_ {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      img {
        width: 80px;
        margin: auto;
      }
      div {
        width: 100%;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        color: white;
      }
    }
  }
}
.list_A {
  background: rgb(50, 139, 168);
}
</style>
